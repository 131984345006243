.hero {
    // background: lighten($primary, 70)
    .lead {
        display: block;
        margin: 1em 0;
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: bolder;
        strong {
            padding-bottom: .05em;
            border-bottom-width: 1px;
            border-bottom-style: dotted; }
        a {
            &:hover {
                text-decoration: none;
                opacity: 0.8; } } }
    .strapline {
        font-size: 1.5em;
 } }        // max-width: 500px

