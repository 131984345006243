$black: rgba(0,0,0,1);
$trans: rgba(0,0,255,0);
.touchpoint-card {
    cursor: pointer;
    transition: .2s all ease-in;
    .card-img-overlay {
 }        // @include linear-gradient( black, black, 0, 0.25)
    img.card-image-top {
        opacity: 0.5; }
    &:hover {
        transform: scale(1.02);
        // @include linear-gradient( black, black, 0, 0.5)
        img.card-image-top {
            opacity: 0.8; } }

    &.half-height {
        // max-height: 200px
        width: 100%;
        img.card-image-top {
            max-height: 300px; } } }


