@import "../../sass/bootstrap/functions";
@import "../../sass/matter/variables";
.page-footer {
    background: darken($primary,50);
    color: white;
    margin: 0 0;
    padding: 6rem 0;
    font-size: 0.7rem;
    line-height: 1.8em;

    h4 {
        color: white;
        font-size: 0.75rem; }

    .opening-times {
        display: none; }

    ul.nav {
        li.nav-item {
            a.nav-link {
                color: darken(white, 20);
                padding: 0;
                &:hover {
                    // color: lighten($primary ,60)
                    opacity: 0.8; } } } } }

