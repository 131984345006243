@import '../../sass/bootstrap/_functions';
@import '../../sass/matter/_variables';
@import '../../sass/bootstrap/_mixins';


@include media-breakpoint-up(md) {

  .imageContainer {
    max-width: 180px; } }
@include media-breakpoint-up(md) {

  .imageContainer {
    max-width: 220px; } }

