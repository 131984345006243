section.counter-section {
    // display: none
    background: #273582;
    margin: 0rem 0rem;
    padding: 6rem 2rem;
    color: white;
    text-align: center;
    .count-number {
        font-size: 2.2rem; }
    .count-title {
        font-size: 0.75rem; } }

