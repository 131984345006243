input {
    letter-spacing: -1px !important; }
.input-group {
    box-shadow: 0 0 1rem rgba(0,0,0,0.1);

    .input-group-text {
        border: 0px solid white;
        background: $gray-900;
        font-size: 2rem;
        padding-left: 1rem;
        color: $gray-400; }
    .form-control, .form-input {
        font-weight: bold;
        box-shadow: 0 0 1rem rgba(0,0,0,0);
        border-color: black;
        outline: 0;
        &:focus {
            box-shadow: 0 0 1rem rgba(0,0,0,0.1);
            border: 10px solid red; } } }

.col-form-label-lg {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343534; }

label {
    font-weight: bold;
    // letter-spacing: 0.01em
    color: $matterDarkBlue; }



