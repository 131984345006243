.carousel {
    min-height: 580px;
    .carousel-item {
        a {
            &:hover {
                text-decoration: none; } } }
    .carousel-indicators {
        bottom: -60px; } }
@media(max-width: 767px) {
    .carousel-control-prev {
        display: none; }
    .carousel-control-next {
        display: none; } }

@media(min-width: 768px) {
    .carousel-control-prev {
        left: -100px; }
    .carousel-control-next {
        right: -100px; } }
@media(min-width: 1200px) {
    .carousel {
        .carousel-item {
            a {
                &:hover {
                    text-decoration: none; } } }
        // .carousel-indicators
        //     bottom: -40px
        .carousel-control-prev {
            left: -150px; }
        .carousel-control-next {
            right: -150px; } } }
