section {
    padding-top: 3rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(sm) {
        padding-top: 5rem;
        padding-bottom: 5rem; }
    .container-fluid {
        padding: 0; }
    .title {
        font-size: 2rem;
        margin: 2rem 2rem 0rem 0rem; }

    &.collapsed {
        margin: 1rem auto;
        padding: 0; }

    &.cta-primary {
        // background: black
        padding-top: 4rem;
        padding-bottom: 4rem;
 } }        // color: white


