section.pricing {
  background: #007bff;
  background: linear-gradient(to right, $primary, darken($primary,10)); }

.pricing {
  .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); }
  .strapline {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 1.25em; }
  hr {
    margin: 1.5rem 0; }
  .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: .1rem;
    font-weight: bold; }
  .card-price {
    font-size: 3rem;
    margin: 0;
    .period {
      margin-left: 2px;
      font-size: 1rem;
      letter-spacing: 1px; } }
  ul li {
    margin-bottom: 1rem; }
  .text-muted {
    opacity: 0.7; }
  .btn {
    // font-size: 80%
    display: block;
    border-radius: .25rem;
    // letter-spacing: .1rem
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s; } }

/* Hover Effects on Card */

@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    .btn {
      opacity: 1; } } }
