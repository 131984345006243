.takeover-page {
    .page-wrapper {
            min-height: 0 !important; }
    body {
        // background: lighten($primary,45)
        // background: lighten($primary,45)
        height: 100vh;
        background: rgba(39,52,130,.81); }
    .btn {
        margin: 0.2em; } }

.page-wrapper-takeover {
    margin-top: 2rem;
    background: rgba(39,52,130,.81);

    .card {
        max-width: 1400px;
        margin: auto;
        padding: 0rem 3rem 1rem 3rem;
        font-size: 0.85rem;

        .normal-title {
            margin: 1rem 0 2rem 0; } }

    .btn-group {
        .btn {
            margin: 0 1em 0 0;
            font-size: 1rem; } } }



@media(max-width:500px) {
    html,body {
        min-height: 100vh; }
    .takeover-page {

        .page-wrapper-takeover {
            .card {
                padding: 0rem 2rem 1rem 2rem;
                font-size: 1em; } } } }
// div.well
//   height: 250px


// .Absolute-Center
//   margin: auto
//   position: absolute
//   top: 0
//   left: 0
//   bottom: 0
//   right: 0


// .Absolute-Center.is-Responsive
//   width: 80%
//   height: 80%
//   min-width: 200px
// //   max-width: 400px
//   padding: 40px




