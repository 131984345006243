.footer-component {
    background: #343534;
    padding: 5rem 0;
    color: white;
    border-top : 1em solid $primary;

    h5 {
        color: $primary; }

    a {
        margin-top: 10px;
        font-weight: bold;
        color: white;
        &:hover {
            color: white; } } }
