// RESPONSIVE RESIZING
html {
    font-size: 1rem; }
body {
    min-height: 100vh;
    background: #0D2240;
    // background-image: white
    background-image: url('../images/background-tile.jpg');
    background-repeat: repeat-y;
    background-size: 100% auto;

    letter-spacing: -0.04em; }

.root {
    min-height: 100vh;
    // height: 100vh
 }    // display: flex

.spinner-container {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spinner-grow {
        width: 5rem;
        height: 5rem; } }


.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    & > [class*=" col-"] {}
    & > [class^="col-"] {
        padding-right: 0;
        padding-left: 0; } }
.flex-grow {
    flex: 1 0 auto; }


html {
    scroll-behavior: smooth;
    height: 100%; }
.is-valigned {
    display: flex;
    align-items: center; }

.navbar-brand {
    cursor: pointer; }

.page-wrapper {
    // display: flex
    // background: white
    min-height: 100vh;
 }    // padding-top: 102px



.form-check-label {
    font-size: 0.8rem;
    color: #666; }

.news-row {
    margin: 5rem 0; }

.fade-enter {
    opacity: 0.01; }

.fade-enter-active {
    opacity: 1;
    transition: all 500ms ease-out; }

.fade-exit {
    opacity: 1; }

.fade-exit-active {
    opacity: 0.01;
    transition: all 500ms ease-in; }


.takeover-page {
    nav.navbar,
    footer.page-footer {
        display: none !important; } }

footer {
    section {
        .title {
            margin: 0 0 0.5em 0; } } }

.home-hero {

    .container-fluid {
        position: relative;
        min-height: 80%;
 }        // background: red
    .title {
        font-size: 3rem; } }

.scroll-wrapper {}

// .scroll-container
//  cursor: pointer
//  margin: auto
//  position: absolute
//  bottom: 30px
//  // width: 50px
//  color: $primary
//  padding: 10px
//  background: transparent
//  border: none
//  left: 45%
//  right: 45%
//  display: block
//  height: 100px
//  transition: .2s opacity ease-out
//  width: 10%
//  min-width: 65px
//  // background: red
//  text-align: center
//  p
//      margin: 0
//      transition: .2s margin ease-out
//  .icon
//      font-size: 3rem
//      transition: .2s margin ease-out
//  &:hover
//      opacity: 0.8
//      p
//          margin-bottom: 1rem


form {
    position: relative; }
.loader-takeover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.6); }

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite; }

@keyframes spin {
    0% {
        transform: rotate(0deg); }
    100% {
        transform: rotate(360deg); } }


.more-less {
    font-size: 1.2em;
    font-style: normal; }


.accordion {
    .card {
        border: none;
        border-bottom: 1px solid #eaeaea !important;
        margin-bottom: 10px; }
    .card-header {
        background: white;
        h5,
        button {
            text-transform: none;
            font-weight: 300;
            letter-spacing: 0;
            font-size: 1.2em; }
        button {
            padding: 0; } } }


.border-pink {
    border-color: $pink !important; }
.border-blue {
    border-color: $indigo !important; }
.border-yellow {
    border-color: $yellow !important; }

ul.special {
    // float: right
    padding: 1em;
    li {

        display: block;
        // padding: 1em 0
        font-weight: normal;
        background: red;
        margin-bottom: 1em;
        background: url(../images/matter-icon-bullet.png) no-repeat left top;
        background-position: 0px 3px;
        min-height: 30px;
        padding-left: 44px;
        padding-top: 0px; } }

@include media-breakpoint-down(md) {
    ul.special.lead {
        font-size: 1em;
        line-height: 1.2em;
        li {
            padding-top: 0.45em; } } }
