@import '../../sass/bootstrap/_functions';
@import '../../sass/matter/_variables';
@import '../../sass/bootstrap/_mixins';


.logo {
    max-height: 45px; }

@include media-breakpoint-up(lg) {

    .navItem {
 } }        // font-size: 1.5rem

