strong {
    font-weight: 700; }

.normal-title {
    font-weight: 300; }


label {
    color: $primary;
    font-size: 1.25rem;
    small {
        font-size: 0.8rem; } }

.serif {
    font-family: $font-family-serif; }

h1,h2,h3,h4,h5,h6 {
    font-weight: $font-weight-bold !important;
    letter-spacing: -0.1em;
    &.section-header {
        position: relative;
        // display: inline-block
        text-align: center;
        // margin: 2rem auto 2rem auto
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            margin: 10px auto;
            max-width: 300px;
            height: 0.5rem;
            background: $matterRed; }
        &.text-left {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin: 10px 0;
                width: 80%;
                height: 0.5rem;
                background: $matterBlue; } }
        &.inverse {
            color: white;
            margin-bottom: 1em;
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin: 10px auto;
                max-width: 100px;
                height: 0.5rem;
                // background: $matterRed
                background: #343534; } } }

    // &.section-header
    //     padding-bottom: 1rem
 }    //     border-bottom: 1rem solid $matterBlue
