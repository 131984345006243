.filter-tabs {
    margin: 1rem 0;
    border-bottom: 5px solid white;
    .nav-item {
        .nav-link {
            color: #999;
            font-size: 1.25rem;
            line-height: 3rem;
            transition: .2s all ease-out;
            border-bottom: 2px solid white;
            display: inline;
            font-weight: bold;
            &.active,
            &:hover {
                color: black;
                border-bottom: 5px solid $primary;
                .nav-link {
                    color: black;
                    cursor: pointer; } } }
        &.complete .nav-link {
            &:before {
                font-family: FontAwesome;
                content: "\f00c";
                display: inline-block;
                padding-right: 3px;
                vertical-align: middle;
                font-weight: 900; } }
        &.disabled .nav-link {
            color: #ccc;
            font-size: 1.25rem;
            line-height: 3rem;
            transition: .2s all ease-out;
            border-bottom: 2px solid white;
            display: inline;
            font-weight: bold;
            &:before {
                content: ""; }
            &.active,
            &:hover {
                color: #ccc;
                border-bottom: 5px solid white;
                .nav-link {
                    color: black;
                    cursor: pointer; } } } } }

// @media(max-width:500px)
//     .filter-tabs
//         .nav-item
//             .nav-link
//                 font-size: 1em
//                 line-height: 2em



